import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../shared/textFieldStyle";

const TagsTranslations = ({ formData, setFormData, formErrors }) => {
  const { t } = useTranslation();
  const steps = [{ label: t("ENGLISH"), value: "EN" }];

  const checkLanguage = (idiom) => {
    return formData?.translations?.some((info) => info.language === idiom) || false;
  };

  const handleChangeCheckBox = (e, value) => {
    const isChecked = e.target.checked;
    const newLanguage = {
      language: value,
      name: "",
      description: "",
    };
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        translations: [...prev?.translations, newLanguage],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        translations: prev?.translations?.filter((language) => language?.language !== value),
      }));
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      translations: prev?.translations?.map((translation, i) => {
        if (i === index) {
          return { ...translation, [name]: value };
        } else {
          return translation;
        }
      }),
    }));
  };

  return (
    <Grid item xs={12} container>
      <FormControl fullWidth sx={focusColor}>
        <Accordion
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--grey-cancelled)",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Typography>{t("TAG_TRANSLATIONS")}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {steps.map((step, index) => (
                <Grid item xs={12} key={index} container alignItems="center">
                  <Checkbox
                    className="checkbox-oniria"
                    checked={checkLanguage(step.value)}
                    onChange={(e) => handleChangeCheckBox(e, step.value)}
                  />
                  <Typography>{step.label}</Typography>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} container mt={2} columnSpacing={2}>
              {formData.translations?.map((translation, index) => (
                <Fragment key={index}>
                  <Grid item xs={12} mb={2}>
                    <Typography>{t("LANGUAGE_" + translation.language)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label={t("NAME")}
                      name="name"
                      value={translation.name}
                      onChange={(e) => handleChange(e, index)}
                      sx={[focusColor, { mb: 2 }]}
                      required
                      error={
                        formErrors?.translations[index].name !== "" &&
                        formErrors?.translations[index].name !== undefined
                      }
                      helperText={formErrors?.translations[index].name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label={t("DESCRIPTION")}
                      name="description"
                      value={translation.description}
                      onChange={(e) => handleChange(e, index)}
                      sx={focusColor}
                      required
                      error={
                        formErrors?.translations[index].description !== "" &&
                        formErrors?.translations[index].description !== undefined
                      }
                      helperText={formErrors?.translations[index].description}
                    />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </FormControl>
    </Grid>
  );
};

export default TagsTranslations;
