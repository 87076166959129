//const BASE_API_URL = "https://kwelxy60tj.execute-api.eu-west-1.amazonaws.com/test";
const BASE_API_URL = "https://kwelxy60tj.execute-api.eu-west-1.amazonaws.com/prod";

const APP_URL = "/v1";

export const API_URL = `${BASE_API_URL}`;

export const SERVICE = {
  //CREATE_USER:`${APP_URL}/users/sign-up-app`,
  ACTIVITIES: `${APP_URL}/events?type=activity`,
  ADMINS: `${APP_URL}/users/users-dashboard`,
  ALLEVENTS: `${APP_URL}/events`,
  ASSISTANT_DETAIL: `${APP_URL}/orders/order-ticket/`,
  ASSISTANTS: `${APP_URL}/tickets/sold/`,
  BASE: APP_URL,
  BILLING: `${APP_URL}/billing`,
  BLOG: `${APP_URL}/blogs/`,
  CARDS: `${APP_URL}/cards/`,
  CATEGORIES_DOMAIN: `${APP_URL}/categories/domain`,
  CATEGORIES: `${APP_URL}/categories`,
  CITIES: `${APP_URL}/utils/cities-of-premises`,
  CNAE: `${APP_URL}/utils/cnaes`,
  CONSUME_EXTRA: `${APP_URL}/orders/consume-extra/`,
  CONSUME_TICKET: `${APP_URL}/orders/consume-ticket/`,
  COUNTRIES: `${APP_URL}/utils/countries`,
  CREATE_ACCOUNT: `${APP_URL}/users/sign-up-dashboard`,
  CREATE_ADMIN: `${APP_URL}/users/add-user`,
  CREATE_USER: `${APP_URL}/users/add-user`,
  DOMAINS: `${APP_URL}/domains`,
  EDIT_USER: `${APP_URL}/users/`,
  EVENTS: `${APP_URL}/events/`,
  EXTRAS: `${APP_URL}/extras/`,
  GROUP_DISCOUNTS: `${APP_URL}/tickets/group-discount/`,
  HOME: `${APP_URL}/utils/home`,
  IMAGE: `${APP_URL}/images`,
  LOGIN: `${APP_URL}/users/login`,
  ORDERS_CARD: `${APP_URL}/orders/card/`,
  ORDERS_EVENT: `${APP_URL}/orders/event/`,
  ORDERS: `${APP_URL}/orders/`,
  ORGANIZATION_VALID: `${APP_URL}/users/validate-uop/`,
  ORGANIZATIONS_VALIDATION: `${APP_URL}/users/pending-uops/`,
  ORGANIZATIONS: `${APP_URL}/organizations/`,
  PARTYS: `${APP_URL}/events?type=event`,
  PAYMENTS: `${APP_URL}/payments`,
  PENDING_USERS: `${APP_URL}/users/pending-users/`,
  PREMISE_VALID: `${APP_URL}/premises/validate-premise/`,
  PREMISES_LIST: `${APP_URL}/premises/list`,
  PREMISES_VALIDATION: `${APP_URL}/premises/pending/`,
  PREMISES: `${APP_URL}/premises/`,
  REFUNDS: `${APP_URL}/refunds`,
  REGIONS: `${APP_URL}/utils/regions/`,
  REJECT_USER: `${APP_URL}/cards/reject-card/`,
  RRPP: `${APP_URL}/users/rrpp`,
  SEPAS: `${APP_URL}/sepas`,
  STATISTICS: `${APP_URL}/utils/statistics`,
  STYLES: `${APP_URL}domains/styles`,
  TICKETS_AND_EXTRAS: `${APP_URL}/tickets/event/ticket-and-extras/`,
  TICKETS: `${APP_URL}/tickets/`,
  TRIPS: `${APP_URL}/events?type=trip`,
  USER: `${APP_URL}/users/`,
  USERS_AFFILIATE: `${APP_URL}/users/users-affiliate/`,
  USERS_PREMISE: `${APP_URL}/users/premise/`,
  VALIDATE_USER: `${APP_URL}/cards/validate-card/`,
  CHECK_URL: `${APP_URL}/events/url-available`,
  CHECK_SKU: `${APP_URL}/events/sku-available`,
  DOMAINS_CATEGORIES: `${APP_URL}/domains/categories`,
  EVENT_SALES: `${APP_URL}/events/sales/`,
  EVENT_SALES_DOMAIN: `${APP_URL}/events/domain/sales/`,
  TAGS: `${APP_URL}/tags`,
};
